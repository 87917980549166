/* You can add global styles to this file, and also import other style files */
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import './theme/custom.scss';
@import './theme/ag-theme-vax.scss';

// Bootstrap overrides
html,
body {
  overscroll-behavior-y: none; // prevents the bounce on iOS safari and chrome
  font-size: var(--vax-font-size-root);
}

// prevents scrolling when our custom modals are presented
[data-modal-open='true'] {
  overflow: hidden;
}

.container {
  max-width: var(--vax-max-width);
  margin: 0 auto;
}

input:focus {
  touch-action: manipulation;
}

// Accordion
.accordion-panel {
  margin-bottom: 20px;

  hr {
    margin: 2rem 0;
  }

  .btn-link {
    --bs-btn-color: var(--vax-primary);
    --bs-btn-padding-x: 0;
    text-decoration: none;
    text-align: left;
    max-width: 100%;
    white-space: inherit;
    font-size: calc(1.2678571429rem + 0.2142857143vw);

    .icon {
      transition: transform 0.3s ease;
      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .card-header {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

// Alerts
.alert-danger {
  color: #000;
  background-color: rgba(218, 50, 50, 0.3);
  border-color: var(--vax-danger);
  padding: 16px;
  gap: 17px;

  .btn-close {
    top: 10px;
    right: 10px;
    background-image: url('assets/icons/x.svg');
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    opacity: 1;
    background-size: auto;
  }
}

.alert-success {
  color: #000;
  background-color: rgba(39, 174, 96, 0.3);
  border-color: var(--vax-success);
  padding: 16px;
  gap: 17px;

  .btn-close {
    top: 10px;
    right: 10px;
    background-image: url('assets/icons/x.svg');
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    opacity: 1;
    background-size: auto;
  }
}

// Badge
.badge {
  border: 1px solid var(--vax-medium-gray-border);
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

// Buttons
.btn {
  max-width: 301px;
}

.btn-primary {
  --bs-btn-bg: var(--vax-primary);
  --bs-btn-border-color: var(--vax-primary);
  --bs-btn-disabled-border-color: var(--vax-primary);
  --bs-btn-hover-bg: var(--vax-primary-hover);
  --bs-btn-active-bg: var(--vax-primary-hover);
  --bs-btn-active-border-color: var(--vax-primary-hover);
  --bs-btn-hover-border-color: var(--vax-primary-hover);
  --bs-btn-disabled-bg: var(--vax-primary);
}

.btn-outline-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-border-color: var(--vax-primary);
  --bs-btn-color: var(--vax-primary);
  --bs-btn-disabled-color: var(--vax-primary);
  --bs-btn-disabled-border-color: var(--vax-primary);
  --bs-btn-hover-bg: var(--vax-primary-hover);
  --bs-btn-active-bg: var(--vax-primary-hover);
  --bs-btn-active-border-color: var(--vax-primary-hover);
  --bs-btn-hover-border-color: var(--vax-primary-hover);

  .icon {
    fill: var(--vax-primary);
  }

  &:hover .icon {
    fill: #fff;
  }
}

.btn-clear {
  --bs-btn-font-weight: 400;
  --bs-btn-font-family: var(--vax-font-family-sans-serif);
}

.btn-dashed {
  border-style: dashed;
}

// Colors
.primary {
  color: var(--vax-primary) !important;
}

.text-primary {
  color: var(--vax-text-primary) !important;
}

.text-secondary {
  color: var(--vax-text-secondary) !important;
}

.text-tertiary {
  color: var(--vax-text-tertiary);
}

// Typography
.text-normal {
  font-weight: 400;
}

.text-semibold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-small {
  font-size: 12px;
}

.text-heading {
  font-family: var(--vax-headings-font);
}

h1,
h3,
h6 {
  font-weight: 700;
}

h2,
h4,
h5 {
  font-weight: 600;
}

// Links
.nav-link {
  color: var(--vax-primary);

  &:hover {
    color: var(--vax-primary-hover);
  }
}

// Ag-Grid
.ag-overlay-loading-center {
  border: 0;
  box-shadow: none;
}

.form-field-error {
  border: 1px solid var(--vax-danger);
}

.form-field-error-icon {
  position: absolute;
  right: 10px;
  top: 12px;
}
