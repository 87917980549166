// Bootstrap theme variables

:root {
  // Config response variables
  // These are used below to set our main app variables
  // Don't use these directly in the apps scss files, prefer using --vax and instead set the variable to one of these
  --theme-text-color: #161616;
  --theme-background-color: #ffffff;
  --theme-tint-color: #385d82;
  --theme-tint-active-color: #386da2;

  // App variables
  // General
  --vax-max-width: 960px; // max width of the container
  --vax-footer-height: 90px;

  // Buttons
  --vax-max-button-height: 62px;
  // Colors
  --vax-primary: var(--theme-tint-color);
  --vax-primary-hover: var(--theme-tint-active-color);
  --vax-text-primary: var(--theme-text-color);
  --vax-text-secondary: rgba(82, 82, 82, 1);
  --vax-text-tertiary: rgb(115, 119, 125);
  --vax-medium-gray: rgb(115, 119, 125);
  --vax-medium-gray-border: rgba(115, 119, 125, 0.6);
  --vax-light-gray-border: rgba(224, 224, 224, 1);
  --vax-danger: #da3232;
  --vax-success: #27ae60;
  --vax-input-placeholder: rgba(188, 190, 192, 1);
  // Cards
  --vax-card-border-radius: 16px;
  --vax-card-background: #ffffff;
  // Typography
  --vax-headings-font: 'Poppins', sans-serif;
  --vax-font-family-sans-serif: 'Open Sans', sans-serif;
  --vax-font-size-root: 14px;
  // Modals
  --vax-modal-backdrop-bg: rgba(0, 0, 0, 0.38);
  --vax-modal-bg: rgb(255, 255, 255);
  --vax-modal-border-radius: 8px;
  --vax-modal-border: 1px solid #abadb1;

  // Bootstrap CSS variables
  // General
  --bs-body-bg: var(--theme-background-color) !important;
  --bs-font-sans-serif: var(--vax-font-family-sans-serif);
  // Cards
  --bs-card-bg: var(--vax-card-background);
  --bs-card-cap-bg: var(--vax-card-background);
  // Colors
  --bs-primary: var(--theme-tint-color);
  --bs-primary-rgb: rgb(var(--theme-tint-color));
  --bs-danger: var(--vax-danger);
  --bs-danger-rgb: 218, 50, 50;
  // Badge
  --bs-badge-color: var(--vax-medium-gray);
  // Nav
  --bs-nav-link-color: var(--vax-primary);
  --bs-nav-link-color-rgb: rgb(var(--vax-primary));
  --bs-nav-link-hover-color: var(--vax-primary-hover);
  --bs-nav-link-hover-color-rgb: rgb(var(--vax-primary-hover));
}

//
// Bootstrap SASS variable overrides
//
// General
$enable-shadows: true;
$enable-dark-mode: false; // TODO: turning dark mode off since we don't have values configured

// Fonts
// Default font family
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: 'Poppins', sans-serif;

// Font sizing
$font-size-root: 14px; // default is 16px, but design p is 14px
$font-size-base: 1rem;
$h1-font-size: calc(1rem * (42px / $font-size-root));
$h2-font-size: calc(1rem * (38px / $font-size-root));
$h3-font-size: calc(1rem * (27px / $font-size-root));
$h4-font-size: calc(1rem * (20px / $font-size-root));
$h5-font-size: calc(1rem * (15px / $font-size-root));
$h6-font-size: calc(1rem * (16px / $font-size-root));

// Badges
$badge-color: var(--bs-badge-color);
$badge-font-size: calc(1em * (14px / $font-size-root));
$badge-font-weight: 400;
$badge-padding-y: 0.2em;
$badge-padding-x: 13px;

// Buttons
// $btn-border-radius: 1.6rem;
$btn-font-family: $headings-font-family;
$btn-box-shadow: 0;
$btn-font-weight: 700;
$btn-border-radius: 8px;
$btn-disabled-opacity: 0.15;
$btn-white-space: break-spaces;
$btn-font-size: calc(1em * (16px / $font-size-root));
$btn-padding-y: 10.5px;
$btn-font-size-lg: calc(1em * (16px / $font-size-root));
$btn-padding-y-lg: 18px;
$btn-font-size-sm: calc(1em * (16px / $font-size-root));
$btn-padding-y-sm: 6px;

// Cards
$card-bg: var(--vax-card-background);
$card-box-shadow: 0px 5px 17px rgba(43, 52, 98, 0.17);
$card-border-radius: var(--vax-card-border-radius);
$card-border-width: 0;
$card-cap-bg: var(--bs-card-cap-bg);
$card-cap-padding-y: 18px;

// Forms
$form-check-input-checked-bg-color: var(--vax-primary);
$form-check-min-height: 30px;
$form-check-input-width: calc(1em * (30px / $font-size-root));
$form-check-padding-start: $form-check-input-width + 1em;
$form-select-box-shadow: 0;
$form-select-indicator-color: #000;
$form-switch-width: calc(1em * (51px / $font-size-root));

// Inputs
$input-box-shadow: 0;
$input-border-radius: 4px;
$input-border-color: var(--vax-medium-gray-border);
$input-focus-border-color: var(--vax-primary);
$input-padding-y: 11px;
$input-placeholder-color: var(--vax-input-placeholder);

// Placeholders (skeletons)
$placeholder-opacity-max: 0.15;
$placeholder-opacity-min: 0.1;
