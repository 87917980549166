.ag-theme-vax {
  // AG Grid CSS Variables
  // https://www.ag-grid.com/angular-data-grid/global-style-customisation-variables/
  // Any styles for custom theme should be loaded after the base theme
  // <!-- grid div applies your class after the theme class -->
  // <div id="myGrid" class="ag-theme-alpine ag-theme-vax"></div>
  // https://www.ag-grid.com/angular-data-grid/global-style-customisation/#creating-a-reusable-package-of-design-customisations
  --ag-alpine-active-color: var(--vax-primary-hover);
  --ag-data-color: var(--vax-text-primary);
  --ag-header-foreground-color: var(--vax-primary);
  --ag-header-background-color: rgba(242, 242, 247, 0.9);
  --ag-foreground-color: var(--vax-text-primary);
  --ag-background-color: var(--vax-card-background);
  --ag-odd-row-background-color: rgba(242, 242, 247, 0.4);
  --ag-borders: 1px solid;
  --ag-border-color: rgba(242, 242, 247, 1);
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: rgba(242, 242, 247, 0.9);
  --ag-font-family: var(--vax-font-family-sans-serif);
  --ag-font-size: var(--vax-font-size-root);
  --ag-column-hover-color: transparent;
  --ag-row-hover-color: rgba(15, 102, 189, 0.15);
}
